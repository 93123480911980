import { MenuOutlined } from "@ant-design/icons";
import moment from "moment";

import {
    Button,
    Card,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Input,
    Menu,
    Row,
    Select,
    Table,
} from "antd";
import { useEffect, useState } from "react";
import {
    DateFormat,
    fetchData,
    postData,
    showError,
    toLocalDateTime,
    toUtc,
} from "../../utils";
import { Parser } from "json2csv";
import _ from "lodash";
import { useBlockScreen, useRoot, useSetActiveTab } from "../../rootContext";
import { useNavigate } from "react-router-dom";
import FormItemLabel from "antd/lib/form/FormItemLabel";
const getColumns = ({ onAction }) => [
    {
        title: "Sku",
        dataIndex: "Sku",
        key: "Sku",
        fixed: "left",
        sorter: true,
    },
    {
        title: "Scansource",
        children: [
            {
                title: "Qty",
                dataIndex: "SsAvailability",
                key: "SsAvailability",
            },
            {
                title: "Price",
                dataIndex: "SsPrice",
                key: "SsPrice",
            },
        ],
    },
    {
        title: "Bluestar",
        children: [
            {
                title: "Qty",
                dataIndex: "BsAvailability",
                key: "BsAvailability",
            },
            // {
            //   title: "Msrp",
            //   dataIndex: "BsMsrp",
            //   key: "BsMsrp",
            // },
            {
                title: "Price",
                dataIndex: "BsPrice",
                key: "BsPrice",
            },
        ],
    },
    {
        title: "Ingram",
        children: [
            {
                title: "Qty",
                dataIndex: "IngAvailability",
                key: "IngAvailability",
            },
            {
                title: "Price",
                dataIndex: "IngCustPrice",
                key: "IngCustPrice",
            },
            // {
            //   title: "Ret. Price",
            //   dataIndex: "IngRetPrice",
            //   key: "IngRetPrice",
            // },
        ],
    },
    //*****************
    {
        title: "Synnex",
        children: [
            {
                title: "Qty",
                dataIndex: "SnxAvailability",
                key: "SnxAvailability",
            },
            {
                title: "Price",
                dataIndex: "SnxPrice",
                key: "SnxPrice",
            },
        ],
    },

    //*****************
    {
        title: "VoIP",
        children: [
            {
                title: "Qty",
                dataIndex: "VoipAvailability",
                key: "VoipAvailability",
            },
            {
                title: "Price",
                dataIndex: "VoipPrice",
                key: "VoipPrice",
            },
        ],
    },    

    {
        title: "Total Qty",
        dataIndex: "TotalQty",
        key: "Id",
        render: (x, item) =>
            [
                item.SsAvailability,
                item.BsAvailability,
                item.IngAvailability,
                item.SnxAvailability,
                item.VoipAvailability, // Include VoIP Qty
            ].reduce((partialSum, a) => partialSum + a, 0),
    },
    {
        title: "Modified Date",
        dataIndex: "Modified",
        key: "Modified",
        render: (x, item) => toLocalDateTime(item.Modified),
    },
    {
        title: "Action",
        dataIndex: "Id",
        key: "x",
        render: (x, item) =>
            ActionMenue({
                onClick: (action) => {
                    onAction({ action, item });
                },
                scheduled: item.ScheduledForUpdate,
                isIndividual: true,
            }),
    },
];
const ActionMenue = (props) => {
    return (
        <Dropdown overlay={menu(props)} placement="bottomLeft">
            <Button>
                <MenuOutlined />
            </Button>
        </Dropdown>
    );
};
const menu = (props) => {
    const items = [
        {
            key: "DELETE",
            label: "Delete",
            onClick: (e) => {
                props.onClick(e.key);
            },
        },
        {
            disabled: !!props.scheduled,
            key: "SCHEDULE",
            label: "Schedule for update",
            onClick: (e) => {
                props.onClick(e.key);
            },
        },
    ];
    props.isIndividual &&
        items.push({
            key: "EDIT",
            label: "Edit",
            onClick: (e) => {
                props.onClick(e.key);
            },
        });
    return <Menu items={items} />;
};

const Pricing = () => {
    const setActiveTab = useSetActiveTab();
    const blocker = useBlockScreen();
    const root = useRoot();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [pageData, setPageData] = useState({ data: [], total: 0 });
    const [filterData, setFilterData] = useState({
        dateFrom: moment().add(-30, "days").format(DateFormat),
        dateTo: moment().format(DateFormat),
    });
    const [pageInfo, setPageInfo] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [form] = Form.useForm();
    const [selectOptions, setSelectOptions] = useState({
        brands: [],
        categories: [],
        platforms: [],
    });
    const navigate = useNavigate();

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const clearFilter = () => {
        const newFilter = {
            dateFrom: moment().add(-30, "days").format(DateFormat),
            dateTo: moment().format(DateFormat),
        };
        setFilterData({ ...newFilter });
        getData({ page: 0, size: 10, filter: newFilter });
    };

    const applyFilter = () => {
        getData({ page: 0, size: 10, filter: filterData });
    };

    const getData = ({ page, size, filter }) => {
        fetchData(
            window.SiteBasePath +
            `/api/pna?page=${page || 0}&size=${size || 10}&dateFrom=${toUtc(filter.dateFrom) || ""
            }&dateTo=${toUtc(filter.dateTo, true) || ""}&skus=${encodeURIComponent(
                filter.skus || ""
            )}&platforms=${(filter.platforms || []).join(",")}&brands=${(
                filter.brands || []
            ).join(",")}&sortDesc=${filter.sortDesc || false}&sortPropProduct=${filter.sortPropProduct || ""
            }`,
            { blocker, root }
        )
            .then((resp) => {
                setPageData({ data: resp.Data, total: resp.Count });

                setSelectedRowKeys([]);
            });
    };

    useEffect(() => {
        getData({
            page: pageInfo.current - 1,
            size: pageInfo.pageSize,
            filter: filterData,
        });
    }, [pageInfo]);

    useEffect(() => {
        fetchData(
            window.SiteBasePath + `/api/Products/master-data?types=platforms,brands`,
            { blocker, root }
        ).then((resp) => {
            setSelectOptions(resp);
        });
    }, []);

    useEffect(() => {
        setActiveTab("pricing");
    }, []);

    const actionMenueProps = {
        onClick: (action) => {
            const items = pageData.data.filter(
                (x) => selectedRowKeys.indexOf(x.Id) > -1
            );
            onAction({ action, items });
        },
    };

    const onAction = ({ action, item, items }) => {
        if (!item && (!items || items.length < 1)) {
            showError("Please select an item first");
            return;
        }
        if (!items) items = [];
        if (item) items.push(item);

        if (action === "SCHEDULE") {
            scheduleItems(items);
        } else if (action === "DELETE") {
            deleteItems(items);
        } else if (action === "EDIT") {
            navigate(`edit-product/${item.ProductId}/p`);
        }
        setSelectedRowKeys([]);
    };
    const scheduleItems = (items) => {
        const ids = items.map((x) => x.ProductId);
        postData(
            window.SiteBasePath + `/api/products/ChangeStatus?status=10`,
            ids,
            { blocker, root }
        ).then((resp) => {
            applyFilter();
        });
    };
    const deleteItems = (items) => {
        const ids = items.map((x) => x.ProductId);
        postData(
            window.SiteBasePath + `/api/products/ChangeStatus?status=99`,
            ids,
            { blocker, root }
        ).then((resp) => {
            applyFilter();
        });
    };

    const exportAll = () => {
        fetchData(
            window.SiteBasePath +
            `/api/pna/for-csv?dateFrom=${toUtc(filterData.dateFrom) || ""}&dateTo=${toUtc(filterData.dateTo, true) || ""
            }&skus=${encodeURIComponent(filterData.skus || "")}&platforms=${(
                filterData.platforms || []
            ).join(",")}&brands=${(filterData.brands || []).join(",")}`,
            { blocker, root }
        ).then((resp) => {
            ceateCsv(resp.Data);
        });
    };
    const exportData = () => {
        ceateCsv(pageData.data);
    };

    const selectPlatformProps = {
        mode: "multiple",
        style: {
            width: "100%",
        },
        value: filterData.platforms || [],
        options: (selectOptions.platforms || []).map((x) => ({
            value: x.Id,
            label: x.Name,
        })),
        onChange: (newValue) => {
            setFilterData({ ...filterData, platforms: newValue });
        },
        placeholder: "Select Platforms...",
        maxTagCount: "responsive",
    };
    const selectBrandProps = {
        mode: "multiple",
        style: {
            width: "100%",
        },
        value: filterData.brands || [],
        options: (selectOptions.brands || []).map((x) => ({
            value: x,
            label: x,
        })),
        onChange: (newValue) => {
            setFilterData({ ...filterData, brands: newValue });
        },
        placeholder: "Select Brands...",
        maxTagCount: "responsive",
    };
    return (
        <>
            <h4 style={{ margin: "10px 0" }}>Pricing and Availablity</h4>
            <Card style={{ marginBottom: "10px" }}>
                <Form form={form} layout="vertical">
                    <Row>
                        <Col span={4}>
                            <Form.Item label="Start Date">
                                <DatePicker
                                    value={filterData.dateFrom ? moment(filterData.dateFrom) : ""}
                                    onChange={(date) => {
                                        let d = date ? date.format(DateFormat) : "";
                                        setFilterData({
                                            ...filterData,
                                            dateFrom: d,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="End Date">
                                <DatePicker
                                    value={filterData.dateTo ? moment(filterData.dateTo) : ""}
                                    onChange={(date) => {
                                        let d = date ? date.format(DateFormat) : "";
                                        setFilterData({
                                            ...filterData,
                                            dateTo: d,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ paddingRight: "10px" }}>
                            <Form.Item label="Skus">
                                <Input
                                    value={filterData.skus}
                                    placeholder="0000, 1111, ..."
                                    onChange={(e) => {
                                        setFilterData({ ...filterData, skus: e.target.value });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ paddingRight: "10px" }}>
                            <Form.Item label="Platforms">
                                <Select label="" {...selectPlatformProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6} style={{ paddingRight: "10px" }}>
                            <Form.Item label="Brands">
                                <Select label="" {...selectBrandProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} style={{ textAlign: "left" }}>
                            <Button onClick={exportData}>Export</Button>
                            <Button onClick={exportAll}>Export All</Button>
                            <ActionMenue {...actionMenueProps} />
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <Button onClick={clearFilter}>Reset</Button>
                            <Button onClick={applyFilter}>Search</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card style={{ marginBottom: "10px" }}>
                <Table
                    loading={loadingData}
                    rowKey="Id"
                    pagination={{ total: pageData.total }}
                    onChange={(pagination, filters, sorter) => {
                        //sorter = sorter.sorter;
                        sorter.column
                            ? setFilterData({
                                ...filterData,
                                sortPropProduct: sorter.field || "",
                                sortDesc: sorter.order === "descend",
                            })
                            : setFilterData({
                                ...filterData,
                                sortPropProduct: "",
                                sortDesc: false,
                            });

                        setPageInfo({ ...pagination });
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <>
                                <strong>Title: </strong>
                                <span>{record.Title}</span>
                                <br></br>
                                <strong>Brand: </strong>
                                <span>{record.Brand}</span>
                                <br></br>
                                <strong>Platforms: </strong>
                                <span>{record.Platforms.map((x) => x.Name).join(", ")}</span>
                            </>
                        ),
                    }}
                    rowSelection={rowSelection}
                    columns={getColumns({ onAction })}
                    dataSource={pageData.data}
                    bordered
                    size="small"
                />
            </Card>
        </>
    );
};
const ceateCsv = (data) => {
    const fields = [
        {
            label: "Sku",
            value: "Sku",
        },
        {
            label: "Scansource Qty",
            value: "SsAvailability",
        },
        {
            label: "Scansource Price",
            value: "SsPrice",
        },
        {
            label: "Synnex Qty",
            value: "SnxAvailability",
        },
        {
            label: "Synnex Price",
            value: "SnxPrice",
        },
        {
            label: "Bluestar Qty",
            value: "BsAvailability",
        },
        {
            label: "Bluestar Price",
            value: "BsPrice",
        },
        {
            label: "Ingram Qty",
            value: "IngAvailability",
        },
        {
            label: "Ingram Price",
            value: "IngCustPrice",
        },
        {
            label: "VoIP Qty",
            value: "VoipAvailability",
        },
        {
            label: "VoIP Price",
            value: "VoipPrice",
        },        

        {
            label: "Total Qty",
            value: (item) =>
                [
                    item.SsAvailability,
                    item.BsAvailability,
                    item.IngAvailability,
                    item.SnxAvailability,
                    item.VoipAvailability, // Include VoIP Qty
                ].reduce((partialSum, a) => partialSum + a, 0),
        },
        {
            label: "Updated On",
            value: (item) => {
                return toLocalDateTime(item.Modified);
            },
        },
    ];
    const json2csvParser = new Parser({ quote: "", fields /*delimiter: "\t"*/ });
    const csv = json2csvParser.parse(data);

    const filename = "pna_" + Date.now() + ".csv";
    const blob = new Blob([csv], { type: "text/csv" });
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
};

export default Pricing;
